@font-face {
  font-family: 'Archivo';
  font-weight: 600;
  src: local('Archivo'), url(./fonts/Archivo/Archivo-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo';
  font-weight: 800;
  src: local('Archivo'), url(./fonts/Archivo/Archivo-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo';
  font-weight: 700;
  src: local('Archivo'), url(./fonts/Archivo/Archivo-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo';
  src: local('Archivo'), url(./fonts/Archivo/Archivo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair';
  font-weight: 600;
  src: local('Playfair'), url(./fonts/Playfair/PlayfairDisplay-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair';
  font-weight: 800;
  src: local('Playfair'), url(./fonts/Playfair/PlayfairDisplay-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair';
  font-weight: 900;
  src: local('Playfair'), url(./fonts/Playfair/PlayfairDisplay-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair';
  font-weight: 700;
  src: local('Playfair'), url(./fonts/Playfair/PlayfairDisplay-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair';
  src: local('Playfair'), url(./fonts/Playfair/PlayfairDisplay-Regular.ttf) format('truetype');
}


.App {
  text-align: center;
  font-weight: 900;
  font-family: Archivo, sans-serif;
}

.main-title{
  font-family: Playfair, serif;
  font-weight: 900;
}
.main-description{
  font-family: Archivo, sans-serif;
  font-weight: 500;
}

.skill-title{
  font-family: Playfair, serif;
  font-weight: 700;
}

.section-title{
  font-family: Playfair, serif;
  font-weight: 900;
}
.section-title.active{
  color: black;
}

/* TIMELINE */

.arrow-square{
  border:solid 10px transparent;
  border-right-color:rgba(0, 31, 53, 1);
  position: absolute;
  margin:-70px 0 0 -20px;
}

.year-event{
  position: absolute;
  margin:-80px 0 0 -55px;
}